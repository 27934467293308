import { create } from "apisauce";

const apiClient = create({
  // baseURL: "https://backend.hkmheavyequipments.qa/api",
  // baseURL: "http://localhost:5000/api",
  baseURL: "http://13.232.215.1:5000/api",
  //

  headers: { Accept: "application/vnd.github.v3+json" },
});

export default apiClient;
