import React, { Fragment, useState } from "react";
import "./Shipping.css";
import MetaData from "../../components/MetaData";
import CheckoutSteps from "./CheckoutSteps";
import { useLocation, useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";
import useAuth from "./../../auth/useAuth";
import swal from "sweetalert";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-modal";
import transit from "../../assets/transit.png";
import clock from "../../assets/clock.png";
import closeBtn from "../../assets/close.png";
import { mobile } from "../../responsive";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    position: "relative",
    backgroundColor: "#f7f7f7",
  },
};
const customStyles2 = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "auto",
    position: "relative",
    backgroundColor: "#f7f7f7",
    display: "flex",
    justifyContent: "center",
  },
};

const Shipping = () => {
  console.log(mobile);
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { user, logIn } = useAuth();
  const [slot, setSlot] = useState();

  const [isModalVisible2, setModalVisible2] = useState(false);
  const [address, setAddress] = useState(
    user ? user.shippingAddress.address : ""
  );
  const [street, setStreet] = useState(
    user ? user?.shippingAddress?.street : ""
  );

  const [pincode, setPincode] = useState(
    user ? user?.shippingAddress?.pincode : null
  );
  const [region, setRegion] = useState(user ? user.shippingAddress?.area : "");
  const [landmark, setLandmark] = useState(
    user ? user?.shippingAddress?.landmark : ""
  );

  const [phoneNo, setPhoneNo] = useState(
    user ? user.shippingAddress.mobileNumber : ""
  );
  const [email, setEmail] = useState(user ? user.shippingAddress.email : "");
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  const products = location?.state?.products;

  const deliveryCharge = location?.state?.deliveryCharge;

  const total1 = location?.state?.totalPrice;

  const orderItems = [];

  const shippingSubmit = async (e) => {
    e.preventDefault();

    if (phoneNo.length < 10 || phoneNo.length > 10) {
      swal("Phone Number should be 8 digits without country code");
      return;
    }

    if (slot) {
      products.map((item) => {
        orderItems.push({
          name: item.product.name,
          qty: item.quantity,
          image: `https://groceryapp-s3bucket.s3.ap-south-1.amazonaws.com/${item.product?._id}.png`,
          price: item?.newprice,
          product: item.product._id,
          mrp: Number(item?.product?.sell_price).toFixed(2),
          discount: Number(
            item?.product?.sell_price - item?.product?.newprice
          ).toFixed(0),
          hsnCode: item?.product?.hsnCode,
        });
      });

      //save shipping add in user model
      const { data } = await apiClient.post("/users/saveshippingaddress", {
        userId: user.id,
        shippingAddress: {
          address,
          street,
          area: region,
          email,
          pincode,
          landmark,
          mobileNumber: phoneNo,
        },
      });

      logIn(data.token);

      navigate("/confirm-order", {
        state: {
          shippingAddress: {
            address,
            street,
            area: region,
            mobileNumber: phoneNo,
            email,
            pincode,
            landmark,
          },

          slot: { slot, selected },
          orderItems,
          subTotal: total1,
          couponCode: location?.state?.couponCode,
          deliveryCharge,
        },
      });
    } else {
      swal("Select Slot");
    }
  };

  const fetchSlots = async (day) => {
    setSlot("");
    setItems([]);
    let formattedDate = `${
      day.getMonth() + 1
    }-${day.getDate()}-${day.getFullYear()}`;

    setSelected(formattedDate);
    const { data } = await apiClient.get("/variations/get-deliveryslot", {
      date: formattedDate,
    });

    setItems(data[0]?.slots ? data[0]?.slots : []);
    setModalVisible2(false);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Fragment>
      <MetaData title="Shipping Details" />
      <CheckoutSteps activeStep={0} />
      <Modal
        isOpen={isModalVisible2}
        onRequestClose={() => setModalVisible2(false)}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <button
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            border: "none",
            background: "transparent",
          }}
          onClick={() => setModalVisible2(false)}
        >
          <img src={closeBtn} alt="" style={{ height: "30px" }} />
        </button>
        <div>
          <p style={{ textAlign: "center" }}>Select Date</p>
          <Calendar
            onClickDay={(day) => {
              fetchSlots(day);
              openModal();
            }}
            value={new Date()}
            // minDate={new Date()}
          />
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{ borderBottom: "1px solid #dfdfdf", marginBottom: "10px" }}
        >
          <p style={{ fontWeight: "500" }}>Slots for {selected}</p>{" "}
          <button
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              border: "none",
              background: "transparent",
            }}
            onClick={closeModal}
          >
            <img src={closeBtn} alt="" style={{ height: "30px" }} />
          </button>
        </div>
        <div>
          {items?.map((item, id) => {
            return (
              <div>
                {item?.quantity < 1 ? (
                  <div
                    key={id}
                    style={{
                      backgroundColor: "white",
                      padding: 10,
                      marginBottom: 10,
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="https://t4.ftcdn.net/jpg/06/03/55/41/360_F_603554140_G3jcEWcN63FTAaKzCmoQ4ZAnIBMjrHpF.jpg"
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />

                    <p
                      style={{
                        color: "black",
                        marginLeft: 10,
                        textDecorationLine: "line-through",
                        opacity: 0.2,
                      }}
                    >
                      {item?.slot}
                    </p>
                  </div>
                ) : (
                  <div
                    key={id}
                    style={{
                      backgroundColor: "white",
                      padding: 10,
                      marginBottom: 10,
                      // textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      setSlot(item?.slot);

                      closeModal();
                    }}
                  >
                    <img
                      src="https://t4.ftcdn.net/jpg/06/03/55/41/360_F_603554140_G3jcEWcN63FTAaKzCmoQ4ZAnIBMjrHpF.jpg"
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />

                    <p style={{ color: "black", marginLeft: 10 }}>
                      {item?.slot}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {items?.length < 1 && (
          <div>
            <p
              style={{
                color: "black",
                textAlign: "center",
                paddingVertical: 10,
                marginTop: "40px",
                fontSize: "24px",
              }}
            >
              No slots found
            </p>
          </div>
        )}
      </Modal>
      <div className="shippingContainer2">
        <div className="shippingBox">
          <h2 className="shippingHeading">Shipping Details</h2>

          <form
            className="shippingForm"
            encType="multipart/form-data"
            onSubmit={shippingSubmit}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "10px",
                width: "90%",
                margin: "0 auto",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setModalVisible2(true)}
            >
              {slot ? (
                <div>
                  <img
                    src={transit}
                    alt=""
                    style={{ height: "40px", marginRight: "10px" }}
                  />
                  <label
                    style={{
                      marginRight: "30px",
                      border: "1px solid #dfdfdf",
                      padding: "5px",
                      borderRadius: "2px",
                    }}
                  >
                    Selected Slot:{" "}
                    <img
                      src={clock}
                      alt=""
                      style={{ height: "20px", marginRight: "5px" }}
                    />
                    {slot}
                  </label>
                </div>
              ) : (
                <div>
                  {!mobile && (
                    <img
                      src={transit}
                      alt=""
                      style={{
                        height: "10px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <label
                    style={{
                      marginRight: "30px",
                      border: "1px solid #dfdfdf",
                      padding: "5px",
                      borderRadius: "2px",
                      width: "100%",
                    }}
                  >
                    Select Delivery Slot
                  </label>{" "}
                </div>
              )}
            </div>

            <div>
              <input
                type="text"
                placeholder="House No."
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Block/Street"
                required
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Area/Locality"
                required
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Landmark"
                required
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Pincode"
                required
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>

            <div>
              <input
                type="mobile"
                placeholder="Phone Number"
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                size="8"
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email Address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="10"
              />
            </div>
            <input
              type="submit"
              value="Continue"
              className="shippingBtn2"
              disabled={address ? false : true}
            />
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Shipping;
