import React from "react";
import Footer from "../../components/Footer";

const TermsConditions = () => {
  return (
    <div
      style={{
        marginTop: "170px",
        textAlign: "justify",
        padding: "20px",
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Terms of use
      </h2>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        “Subhash Super Store” is a unit of “Mittal Trading Company at C-2036
        Indira Nagar, Lucknow. The domain name www.SubhashSuperStore.in is owned
        by the Company. The Company holds a FSSAI license no. 12723066000661 "
        Subhash Super Store " is owned & managed by " Mittal Trading Company”
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Acceptance of terms
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        Thank you for using Subhash Super Store. These Terms of Use (the
        "Terms") are intended to make you aware of your legal rights and
        responsibilities with respect to your access to and use of the
        SubhashSuperStore.in website at www.SubhashSuperStore.in (the "Site")
        and any related mobile or software applications ("SubhashSuperStore
        Platform") including but not limited to delivery of information via the
        website whether existing now or in the future that link to the Terms
        (collectively, the "Services"). These Terms are effective for all
        existing and future SubhashSuperStore customers. By accessing this site
        (hereinafter the “Marketplace”), you agree to be bound by the same and
        acknowledge that it constitutes an agreement between you and the Company
        (hereinafter the “User Agreement”). You may not use the Services if you
        do not accept the Terms or are unable to be bound by the Terms. Your use
        of the SubhashSuperStore Platform is at your own risk, including the
        risk that you might be exposed to content that is objectionable, or
        otherwise inappropriate. This document is published in accordance with
        the provisions of Rule 3 of the Information Technology (Intermediaries
        Guidelines) Rules, 2011. The User Agreement may be updated from time to
        time by the Company without notice. It is therefore strongly recommended
        that you review the User Agreement, as available on the Marketplace,
        each time you access and/or use the Marketplace. The terms ‘visitor(s)’,
        ‘user(s)’, ‘you’ hereunder refer to the person visiting, accessing,
        browsing through and/or using the Marketplace at any point in time.
        Should you have need clarifications regarding the Terms of Use, please
        do write to us at at info@ SubhashSuperStore.in
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Services overview
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Marketplace is a platform for domestic consumers to transact with
        third party sellers, who have been granted access to the Marketplace to
        display and offer products for sale through the Marketplace. For
        abundant clarity, the Company does not provide any services to users
        other than providing the Marketplace as a platform to transact at their
        own cost and risk, and other services as may be specifically notified in
        writing. The Company is not and cannot be a party to any transaction
        between you and the third party sellers, or have any control,
        involvement or influence over the products purchased by you from such
        third party sellers or the prices of such products charged by such
        third-party sellers. The Company therefore disclaims all warranties and
        liabilities associated with any products offered on the Marketplace.
        Services on the Marketplace are available to only select geographies in
        Lucknow, and are subject to restrictions based on business hours.
        Transactions through the Marketplace may be subject to a delivery charge
        where the minimum order value is not met. You will be informed of such
        delivery charges at the stage of check-out for a transaction through the
        Marketplace. Transactions through the Marketplace may be subject to a
        convenience & safety fee. You will be informed of such convenience &
        safety fee at the stage of check-out for a transaction through the
        Marketplace.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Eligibility
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        Persons who are “incompetent to contract” within the meaning of the
        Indian Contract Act, 1872 including minors, undischarged insolvents etc.
        are not eligible to use/access the Marketplace. However, if you are a
        minor, i.e. under the age of 18 years, you may use/access the
        Marketplace under the supervision of an adult parent or legal guardian
        who agrees to be bound by these Terms of Use. You are however prohibited
        (even under provision) from purchasing any product(s) which is for adult
        consumption, the sale of which to minors is prohibited. The Marketplace
        is intended to be a platform for end-consumers desirous of purchasing
        product(s) for domestic self-consumption. If you are a retailer,
        institution, wholesaler or any other business user, you are not eligible
        to use the Marketplace to purchase products. The Company, in its sole
        discretion and without liability, reserves the right to terminate or
        refuse your registration, or refuse to permit use/access to the
        Marketplace, if: (i) it is discovered or brought to notice that you do
        not conform to the eligibility criteria, or (ii) the Company has reason
        to believe (including through evaluating usage patterns) that the
        eligibility criteria is not met/is violated by a user, or (iii) may
        breach the terms of this User Agreement. In order to determine
        compliance with eligibility criteria, the Company inter alia uses an
        algorithm and/or pre-determined criteria based technology and
        accordingly, from time to time, your usage may be restricted or blocked
        on account of overlap with such algorithms/predetermined criteria. In
        such cases, if you are a genuine domestic user of the Platform, please
        contact us for assistance.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Licence & access
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Company grants you a limited sub-license to access and make personal
        use of the Marketplace, but not to download (other than page caching) or
        modify it, or any portion of it, except with express prior written
        consent of the Company. Such limited sublicense does not include/permit
        any resale or commercial use of the Marketplace or its contents; any
        collection and use of any product listings, descriptions, or prices. The
        Marketplace or any portion of the Marketplace may not be reproduced,
        duplicated, copied, sold, resold, visited, or otherwise exploited for
        any commercial purpose without express prior written consent of the
        Company You may not frame or utilize framing techniques to enclose any
        trademark, logo, or other proprietary information (including images,
        text, page layout, or form) of the Marketplace or of the Company and/or
        its affiliates without the express prior written consent of the Company.
        You may not use any meta tags or any other “hidden text” utilizing the
        Company’s name or trademarks without the express prior written consent
        of the Company. You shall not attempt to gain unauthorized access to any
        portion or feature of the Marketplace, or any other systems or networks
        connected to the Marketplace or to any server, computer, network, or to
        any of the services offered on or through the Marketplace, by hacking,
        ‘password mining’ or any other illegitimate means. You hereby agree and
        undertake not to host, display, upload, modify, publish, transmit,
        update or share any information which: · Belongs to another person and
        to which you do not have any right; · Is grossly harmful, harassing,
        blasphemous, defamatory, obscene, pornographic, paedophilic, libelous,
        invasive of another’s privacy, hateful, or racially, ethnically
        objectionable, disparaging, relating or encouraging money laundering or
        gambling, or otherwise unlawful in any manner whatever; · Harms minors
        in any way; · Infringes any patent, trademark, copyright or another
        proprietary/intellectual property right; · Violates any law for the time
        being in force; · Deceives or misleads the addressee about the origin of
        such messages communicates any information which is grossly offensive or
        menacing in nature; · Contains software viruses or any other computer
        code, files or programs designed to interrupt, destroy or limit the
        functionality of any computer resource; · Threatens the unity,
        integrity, defence, security or sovereignty of India, friendly relations
        with foreign states, or public order or causes incitement to the
        commission of any cognizable offense or prevents investigation of any
        offense or is insulting any other nation; · Is misleading or known to be
        false in any way. Any unauthorized use shall automatically terminate the
        permission or sub-license granted by the Company.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Account & registration obligations
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        All users must register and log in for placing orders on the
        Marketplace. You must keep your account and registration details current
        and correct for all communications related to your purchases from the
        Marketplace. By agreeing to the Terms of Use, you agree to receive
        promotional or transactional communication and newsletters from the
        Company. You can opt out from such communication and/or newsletters
        either by contacting the customer services team of Marketplace or by
        placing a request for the same. As part of the registration process on
        the Marketplace, the Company may collect the following personally
        identifiable information about you, including but not limited to name,
        email address, age, address, mobile phone number, and other contact
        details, demographic profile (like your age, gender, occupation,
        education, address etc.) and information about the pages on the
        Marketplace you visit/access, the links you click on the Marketplace,
        the number of times you access a particular page/feature and any such
        information. Information collected about you is subject to the privacy
        policy of the Company (https:// SubhashSuperStore /privacy), which is
        incorporated in these Terms of Use by reference. By registering your
        mobile number with us, you provide your consent to be contacted by us
        via phone calls, SMS notifications, mobile applications and/or any other
        electronic mode of communication in case of any order or shipment or
        delivery related updates.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>Pricing</h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Company aims to ensure that prices of all products offered for sale
        are true and correct. However, from time to time, the prices of certain
        products may not be current or may be inaccurate on account of technical
        issues, typographical errors or incorrect product information provided
        to the Company. In each such case, notwithstanding anything to the
        contrary, the Company reserves the right to cancel the order without any
        further liability. Subject to the foregoing, the price mentioned at the
        time of ordering a product shall be the price charged at the time of
        delivery, provided that no product offered for sale on the Marketplace
        will be sold at a price higher than its MRP (maximum retail price).
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Disclaimers
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        · You acknowledge and undertake that you are accessing the services on
        the website and transacting at your own risk and are using your best and
        prudent judgement before entering into any transactions. The Company
        makes best efforts to display available Products, including in respect
        of their colour, size, shape and appearance, as accurately as possible.
        However, as the same are representative accordingly the actual color,
        size, shape and appearance may have variations from the depiction on
        your mobile/computer screen. · The Company does not make any
        representation or warranties in respect of the products available on the
        Marketplace nor does the Company implicitly or explicitly support or
        endorse the sale or purchase of any products on the Marketplace. The
        Company accepts no liability for any error. · You acknowledge and agree
        that the Company shall not, at any time, have any right, title or
        interest to any product(s), which is subject to a bipartite sale and
        purchase contract between you. The Company does not have any control
        over the quality, failure to provide or any other aspect whatsoever of
        the product(s) and is not responsible for damages or delays on account
        of products which are out of stock, unavailable or back ordered.
      </p>
      <Footer />
    </div>
  );
};

export default TermsConditions;
