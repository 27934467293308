import React from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const ProductCard = ({ product }) => {
  const discount = (product.discount / 100) * product.sell_price;
  const newprice = Number(product.sell_price - discount).toFixed(2);

  const imgsrc = `https://groceryapp-s3bucket.s3.ap-south-1.amazonaws.com/${product?._id}.png`;

  return (
    <Link
      className="productCard"
      to={`/product/${product?.groupId}`}
      state={{ Data: product }}
    >
      <img
        src={imgsrc}
        alt={product.name}
        style={{ height: "180px", width: "100%", objectFit: "contain" }}
      />
      {product?.discount > 0 && (
        <div className="deal-container">
          {Number(product?.discount).toFixed(0)}% OFF
        </div>
      )}
      {/* <FavoriteBorderIcon className="fav-icon" /> */}
      <p>{product.name}</p>

      {discount > 0 ? (
        <>
          <span
            style={{
              fontSize: "22px",
            }}
          >
            ₹ {`${newprice}`}
          </span>

          <span
            style={{
              textDecoration: "line-through",
              color: "grey",
              fontSize: "18px",
            }}
          >
            ₹ {`${product.sell_price}`}
          </span>
        </>
      ) : (
        <span
          style={{
            fontSize: "22px",
          }}
        >
          ₹ {`${product.sell_price}`}
        </span>
      )}
    </Link>
  );
};

export default ProductCard;
